import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import bgImageLarger from '@/assets/images/bg_header-larger.svg'
import bgImageSmall from '@/assets/images/bg_header-small.svg'

import { white } from '@/style-guide/colors'
import { Badge as BadgeComponent } from '@/style-guide/'

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: 50px;

  ${media.greaterThan('medium')`
    top: 0px;
  `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-image: url(${bgImageSmall});
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 430px;
  padding: 40px 32px;

  ${media.greaterThan('medium')`
    height: 369px;
    background-image: url(${bgImageLarger});
    height: 680px;
    padding-top: 165px;
    padding-left: 153px;
    padding-right: 153px;
  `}
`

const Badge = styled(BadgeComponent)`
  display: none;

  ${media.greaterThan('medium')`
    display: flex;
  `}
`

const Title = styled.span`
  color: ${white};
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  font-size: 27px;
  line-height: 37px;

  ${media.greaterThan('medium')`
    text-align: center;
    font-size: 42px;
    line-height: 50px;
    max-width: 543px;
    margin-top: 20px;
  `}
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #d1e0fe;
  margin-top: 10px;

  ${media.greaterThan('medium')`
  margin-top: 35px;
    text-align: center;
    width: 728px;
    font-size: 16px;
    line-height: 26px;
    margin-top: 15px;
  `}
`

const Header = () => (
  <Container>
    <Content>
      <Badge badge="Us" content="We make decisions more rational, one calculation at a time." />
      <Title>About Us</Title>
      <Description>
        Far too often, we perceive the world through the lens of our emotions, feelings, and
        intuition. Meanwhile, a multitude of our problems can be solved with a tiny bit of math. Buy
        or rent a house? How long to boil an egg? Am I overweight? What it takes to answer these
        questions is a clear mind, a few minutes, and a mathematical formula. Three things that we
        often lack, so we go with hunches instead.
      </Description>
    </Content>
  </Container>
)

export default Header
